<template>
  <div class="patients">
    <v-container>
      <h1>Patients</h1>
      <v-spacer></v-spacer>
      <br />
      <v-card rounded="lg">
        <v-data-table
          :headers="headers"
          :items="patients"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :search="search"
          hide-default-footer
          @page-count="pageCount = $event"
          rounded="lg"
        >
          <template v-slot:top>
            <v-toolbar rounded="lg" flat>
              <v-toolbar-title
                ><v-text-field
                  filled
                  single-line
                  clearable
                  dense
                  hide-details
                  placeholder="Search"
                  background-color="white"
                  prepend-inner-icon="mdi-magnify"
                  class="search"
                  v-model="search"
                  style="width: 350px;"
                ></v-text-field
              ></v-toolbar-title>

              <v-spacer></v-spacer>
              <!-- edit dialog -->
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">Edit Patient</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="selectedPatient.fname"
                            label="First Name"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="selectedPatient.lname"
                            label="Last Name"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="selectedPatient.phoneNumber"
                            label="Phone Number"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="selectedPatient.primaryDoctor"
                            label="Primary Doctor"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="selectedPatient.email"
                            label="Email"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="selectedPatient.medicalcard.age"
                            label="Age"
                            disabled
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="8">
                          <v-dialog
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            min-width="290px"
                            max-width="290px"
                            @change="getAge()"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="tempBirthday"
                                append-icon="mdi-calendar"
                                label="Birthdate"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                                outlined
                                rounded
                                dense
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="tempBirthday"
                              :max="new Date().toISOString().substr(0, 10)"
                              min="1900-01-01"
                              label="Birthdate"
                              ><v-btn
                                text
                                color="primary"
                                @click="saveBirthday()"
                                >Save</v-btn
                              ></v-date-picker
                            >
                          </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="selectedPatient.medicalcard.gender"
                            label="Gender"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="selectedPatient.medicalcard.weight"
                            label="Weight (lbs)"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="selectedPatient.medicalcard.height"
                            label="Height (in)"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="selectedPatient.medicalcard.address"
                            label="Address"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="selectedPatient.medicalcard.pharmacy"
                            label="Pharmacy Address"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this patient?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.past="{ item }">
            <v-icon class="mr-2" @click="viewPast(item)">
              mdi-calendar-clock
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2" @click="editItem(item)">
              mdi-pencil-outline
            </v-icon>
            <v-icon color="red" @click="deleteItem(item)">
              mdi-trash-can
            </v-icon>
          </template>
          <template v-slot:no-data>
            No patients available
          </template>
          <template v-slot:item.mcard="{ item }">
            <v-avatar
              @click="openCard(item)"
              size="35"
              style="cursor: pointer;"
            >
              <img v-if="!item.photoURL" src="@/assets/avatar.png" />
              <img v-else :src="item.photoURL" />
            </v-avatar>
          </template>
        </v-data-table>
        <div class="d-flex pt-2">
          <v-pagination
            class="justify-left ml-3 mb-6"
            v-model="page"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-card>

      <!-- medical card -->

      <v-dialog v-model="viewCard" max-width="400px">
        <MedicalCardDialog :selectedPatient="selectedPatient" />
      </v-dialog>
      <v-dialog v-model="viewPastDialog" :key="selectedPatient.id" fullscreen>
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title
              >{{ selectedPatient.fname }}
              {{ selectedPatient.lname }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="viewPastDialog = false">
                Close
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <AllPastAppointments
              :patientId="selectedPatient.id"
              :practiceId="practice.id"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import * as fb from '../firebase'
import * as moment from 'moment'
import ProxyLayout from '@/layouts/ProxyLayout'
import MedicalCardDialog from '../components/dialogs/MedicalCardDialog'
import AllPastAppointments from '@/components/dialogs/AllPastAppointments'
import {
  logPatientViewed,
  logPatientEdited,
  logPatientDeleted
} from '../Events'
export default {
  name: 'Patients',
  props: ['user'],
  components: {
    MedicalCardDialog,
    AllPastAppointments
  },
  data() {
    return {
      viewPastDialog: false,
      dialog: false,
      dialogDelete: false,
      menu: false,
      viewCard: false,
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'View', value: 'mcard', sortable: false },
        { text: '', value: 'past' },
        {
          text: 'First Name',
          align: 'start',
          value: 'fname'
        },
        {
          text: 'Last Name',
          value: 'lname'
        },
        { text: 'Email', value: 'email' },
        { text: 'Phone Number', sortable: false, value: 'phoneNumber' },
        { text: 'Primary Doctor', value: 'primaryDoctor' },
        { text: '', value: 'actions', sortable: false }
      ],
      patients: [],
      selectedPatient: {
        fname: '',
        lname: '',
        email: '',
        phoneNumber: '',
        primaryDoctor: '',
        id: '',
        medicalcard: {
          age: '',
          birthdate: '',
          gender: '',
          height: '',
          weight: '',
          address: '',
          pharmacy: ''
        }
      },
      tempBirthday: '',
      practice: {
        id: ''
      },
      currentUser: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
          this.practice.id = this.currentUser.practiceId
          this.$bind(
            'patients',
            fb.patientsCollection.where(this.practice.id, '==', true)
          )
        }
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    selectedPatient: function() {
      this.tempBirthday = this.selectedPatient.medicalcard.birthdate
        .toDate()
        .toISOString()
        .substr(0, 10)
    }
  },
  created() {
    this.$emit(`update:layout`, ProxyLayout)
  },
  methods: {
    viewPast(item) {
      this.selectedPatient = { id: item.id, ...item }
      this.viewPastDialog = true
    },
    openCard(item) {
      this.selectedPatient = Object.assign(
        {
          medicalcard: {
            age: '',
            birthdate: '',
            gender: '',
            height: '',
            weight: '',
            address: '',
            pharmacy: ''
          }
        },
        item,
        { id: item.id }
      )
      this.viewCard = true
      logPatientViewed(
        this.currentUser.id,
        this.practice.id,
        this.selectedPatient.id
      )
    },
    formatViDate(date) {
      date = this.selectedPatient.medicalcard.birthdate
      return moment(date).format('MM/DD/YYYY')
    },
    saveBirthday() {
      this.getAge()
      this.menu = false
    },
    getAge() {
      var birthday = this.tempBirthday
      const age = moment().diff(birthday, 'years')
      this.selectedPatient.medicalcard.age = age
      this.selectedPatient.medicalcard.birthdate = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(this.tempBirthday)
      )
    },
    editItem(item) {
      if (!item.primaryDoctor) {
        item.primaryDoctor = '---'
      }
      this.selectedPatient = Object.assign(
        {
          medicalcard: {
            age: '',
            birthdate: '',
            gender: '',
            height: '',
            weight: '',
            address: '',
            pharmacy: ''
          }
        },
        item
      )
      this.selectedPatient.id = item.id
      this.dialog = true
    },
    deleteItem(item) {
      this.selectedPatient = Object.assign({}, item)
      this.selectedPatient.id = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      try {
        await fb.patientsCollection.doc(this.selectedPatient.id).set(
          {
            [this.practice.id]: false
          },
          { merge: true }
        )
        logPatientDeleted(
          this.currentUser.id,
          this.practice.id,
          this.selectedPatient.id
        )
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
      this.closeDelete()
    },

    close() {
      this.dialog = false
    },

    closeDelete() {
      this.dialogDelete = false
    },

    async save() {
      this.getAge()
      await fb.patientsCollection.doc(this.selectedPatient.id).set(
        {
          fname: this.selectedPatient.fname,
          lname: this.selectedPatient.lname,
          email: this.selectedPatient.email,
          phoneNumber: this.selectedPatient.phoneNumber,
          primaryDoctor: this.selectedPatient.primaryDoctor,
          medicalcard: {
            age: this.selectedPatient.medicalcard.age,
            birthdate: this.selectedPatient.medicalcard.birthdate,
            gender: this.selectedPatient.medicalcard.gender,
            height: this.selectedPatient.medicalcard.height,
            weight: this.selectedPatient.medicalcard.weight,
            address: this.selectedPatient.medicalcard.address,
            pharmacy: this.selectedPatient.medicalcard.pharmacy
          }
        },
        { merge: true }
      )

      logPatientEdited(
        this.currentUser.id,
        this.practice.id,
        this.selectedPatient.id
      )
      this.close()
    }
  }
}
</script>
