<template>
  <div>
    <v-card-title>Past Appointments <v-spacer></v-spacer> </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="appointments"
        sort-by="date"
        class="elevation-1"
        no-data-text="No appointments found"
      >
        <template v-slot:item.startTime="{ item }">
          <div v-html="formatDate(item.startTime)"></div>
        </template>
        <template v-slot:item.view="{ item }">
          <v-icon @click="viewItem(item)" color="primary">
            open_in_new
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>
<script>
import * as fb from '@/firebase'
import moment from 'moment'

export default {
  name: 'AllPastAppointments',
  props: ['patientId', 'practiceId'],
  data() {
    return {
      appointments: [],
      appointment: {},
      headers: [
        { text: 'Date', value: 'startTime' },
        { text: 'View', value: 'view' }
      ]
    }
  },
  async created() {
    const appointments = await fb.db
      .collection(`Practices/${this.practiceId}/Appointments`)
      .where('patientId', '==', this.patientId)
      .orderBy('startTime', 'desc')
      .get()
    for (const appointment of appointments.docs) {
      this.appointments.push({
        id: appointment.id,
        practiceId: this.practiceId,
        uniqueName: appointment.data().uniqueRoom,
        ...appointment.data()
      })
    }
  },
  // async mounted() {
  //   await fb.practicesCollection.get().then(async practices => {
  //     for (const practice of practices.docs) {
  //       const appointments = await fb.practicesCollection
  //         .doc(practice.id)
  //         .collection('Appointments')
  //         .where('patientId', '==', this.patientId)
  //         .orderBy('startTime', 'asc')
  //         .get()

  //       for (const appointment of appointments.docs) {
  //         const endTime = moment(appointment.data().endTime.toDate())
  //         if (
  //           appointment.id !== this.uniqueName &&
  //           endTime.isBefore(moment())
  //         ) {
  //           this.appointments.push({
  //             id: appointment.id,
  //             practiceId: practice.id,
  //             uniqueName: appointment.data().uniqueRoom,
  //             ...appointment.data()
  //           })
  //         }
  //       }
  //     }
  //   })
  // },
  methods: {
    formatDate(date) {
      return moment(date.toDate()).format('LLLL')
    },
    viewItem(item) {
      let routeData = this.$router.resolve({
        name: 'past-appointment',
        query: { un: item.uniqueName, pa: item.patientId, pr: item.practiceId }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
